// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}


// Viewport additional helpers

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }


// Viewport additional helpers

.mw-80{ max-width: 80% !important; }
.mh-80{ max-height: 80% !important; }

.min-v80 { min-width: 80vw !important; }
.min-v80 { min-height: 80vh !important; }

.vw-80{ width: 80vw !important; }
.vh-80{ height: 80vh !important; }

// Viewport additional helpers

.mw-60{ max-width: 60% !important; }
.mh-60{ max-height: 60% !important; }

.min-v60 { min-width: 60vw !important; }
.min-v60 { min-height: 60vh !important; }

.vw-60{ width: 60vw !important; }
.vh-60{ height: 60vh !important; }

// Viewport additional helpers

.mw-50{ max-width: 50% !important; }
.mh-50{ max-height: 50% !important; }

.min-v50 { min-width: 50vw !important; }
.min-v50 { min-height:54vh !important; }

.vw-50{ width: 50vw !important; }
.vh-50{ height: 50vh !important; }


// Viewport additional helpers

.mw-40{ max-width: 40% !important; }
.mh-40{ max-height: 40% !important; }

.min-v40 { min-width: 40vw !important; }
.min-v40 { min-height: 40vh !important; }

.vw-40{ width: 40vw !important; }
.vh-40{ height: 40vh !important; }


// Viewport additional helpers

.mw-30{ max-width: 30% !important; }
.mh-30{ max-height: 30% !important; }

.min-v30 { min-width: 30vw !important; }
.min-v30 { min-height: 30vh !important; }

.vw-30{ width: 30vw !important; }
.vh-30{ height: 30vh !important; }
