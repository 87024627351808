// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Cinzel+Decorative&family=Condiment&family=Exo+2&family=Gruppo&family=Heebo:wght@200&family=Montserrat:ital@0;1&family=Play&family=Russo+One&family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


